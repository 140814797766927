import FilterAlt from '@mui/icons-material/FilterAlt';
import { TextFieldProps } from '@mui/material/TextField';

import StyledTextField from 'src/components/AssetTemplateForm/StyledTextField';

interface Props
  extends Pick<
    TextFieldProps,
    'size' | 'fullWidth' | 'onChange' | 'error' | 'name'
  > {
  filterBy: string;
}

export default function TextFilterInput({
  size,
  fullWidth,
  filterBy,
  name,
  onChange,
  error,
}: Props): JSX.Element {
  return (
    <StyledTextField
      size={size}
      fullWidth={fullWidth}
      label="Filter"
      name={name}
      value={filterBy}
      onChange={onChange}
      error={error && !!filterBy}
      InputProps={{
        endAdornment: <FilterAlt />,
      }}
    />
  );
}

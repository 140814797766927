import { ChangeEvent, useEffect, useLayoutEffect, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { ZodSchema, z } from 'zod';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { modifyUser, ModifyUserProps, signUp, SignUpProps } from 'src/api';
import { Flexbox } from 'src/components/Alignments';
import BlockQuote from 'src/components/BlockQuote';
import useAlertSnackbar from 'src/hooks/useAlertSnackbar';
import useConfirmModal from 'src/hooks/useConfirmModal';
import { myInfoState } from 'src/states/myInfo';
import { Modality } from 'src/types/api/data/image';
import { User } from 'src/types/client/user';

import CommonDialog, { CommonDialogProps } from './CommonDialog';

const PermissionChecks = styled('label')`
  padding: 10px 0;
`;

interface AnnotatorAccountDialogProps extends CommonDialogProps {
  user: User | null;
}

export enum SCHEMA_VALIDATION_ERROR {
  USERNAME = 'Username can only contain English letters, numbers, and underscores',
  USERNAME_MIN_LENGTH = 'Username must be at least 4 characters',
  NAME = 'Name is a required field',
  PASSWORD_UPPERCASE = 'Password must include at least 1 uppercase letter',
  PASSWORD_LOWERCASE = 'Password must include at least 1 lowercase letter',
  PASSWORD_NUMBER = 'Password must include at least 1 number',
  PASSWORD_SPECIAL = 'Password must include at least 1 special character:  #?!@$^%&*-',
  PASSWORD_ILLEGAL = 'Password must not contain spaces or illegal characters',
  PASSWORD_MIN_LENGTH = 'Password must contain at least 10 characters',
  PASSWORD_MAX_LENGTH = 'Password must contain less than 100 characters',
  PASSWORDS_MISMATCH = 'Passwords do not match',
  MODALITY = 'Please select at least one modality',
  COUNT = 'Please enter a number',
  COUNT_INT = 'Whole numbers only',
  COUNT_MIN = 'Please enter a number greater than 0',
}

const baseSchema = z.object({
  isAdmin: z.boolean(),
  username: z
    .string()
    .min(4, { message: SCHEMA_VALIDATION_ERROR.USERNAME_MIN_LENGTH })
    .refine(value => !/\W/.test(value), {
      message: SCHEMA_VALIDATION_ERROR.USERNAME,
    }),
  name: z.string().nonempty(SCHEMA_VALIDATION_ERROR.NAME),
  password: z
    .string()
    .min(10, { message: SCHEMA_VALIDATION_ERROR.PASSWORD_MIN_LENGTH })
    .max(100, { message: SCHEMA_VALIDATION_ERROR.PASSWORD_MAX_LENGTH })
    .regex(/[A-Z]/, {
      message: SCHEMA_VALIDATION_ERROR.PASSWORD_UPPERCASE,
    })
    .regex(/[a-z]/, {
      message: SCHEMA_VALIDATION_ERROR.PASSWORD_LOWERCASE,
    })
    .regex(/[0-9]/, {
      message: SCHEMA_VALIDATION_ERROR.PASSWORD_NUMBER,
    })
    .regex(/[#?!@$^%&*-]/, {
      message: SCHEMA_VALIDATION_ERROR.PASSWORD_SPECIAL,
    })
    .refine(value => !/[^0-9a-zA-Z#?!@$^%&*-]/.test(value), {
      message: SCHEMA_VALIDATION_ERROR.PASSWORD_ILLEGAL,
      path: ['password'],
    }),
  passwordConfirm: z.string(),
  checkCXR: z.boolean(),
  checkMMG: z.boolean(),
  checkDBT: z.boolean(),
  count: z
    .number({ message: SCHEMA_VALIDATION_ERROR.COUNT })
    .int({ message: SCHEMA_VALIDATION_ERROR.COUNT_INT })
    .min(1, { message: SCHEMA_VALIDATION_ERROR.COUNT_MIN })
    .default(1),
});

export type AccountForm = z.infer<typeof baseSchema>;

const editSchema = baseSchema.omit({
  password: true,
  passwordConfirm: true,
  count: true,
});

const createSingleUserSchema = baseSchema.omit({
  count: true,
});

const bulkCreateSchema = baseSchema.omit({
  name: true,
});

const hasAtLeastOneModality = (data: AccountForm) => {
  return data.checkCXR || data.checkMMG || data.checkDBT;
};

const createSchema = (isBulkUserCreateMode: boolean) =>
  isBulkUserCreateMode ? bulkCreateSchema : createSingleUserSchema;

const refinedSchemaWithAtLeastOneModality = (rawSchema: ZodSchema) =>
  rawSchema.refine(hasAtLeastOneModality, {
    message: SCHEMA_VALIDATION_ERROR.MODALITY,
    path: ['checkCXR'],
  });

const refinedCreateSchema = (isBulkUserCreateMode: boolean) =>
  refinedSchemaWithAtLeastOneModality(
    createSchema(isBulkUserCreateMode)
  ).refine(data => data.password === data.passwordConfirm, {
    message: SCHEMA_VALIDATION_ERROR.PASSWORDS_MISMATCH,
    path: ['passwordConfirm'],
  });

const refinedEditSchema = refinedSchemaWithAtLeastOneModality(editSchema);

export const finalSchema = (
  isCreatingNewUser: boolean,
  bulkUserCreateMode: boolean
): ZodSchema =>
  isCreatingNewUser
    ? refinedCreateSchema(bulkUserCreateMode)
    : refinedEditSchema;

export default function AnnotatorAccountDialog({
  open,
  onClose,
  user,
}: AnnotatorAccountDialogProps): JSX.Element {
  const [isCreating, setIsCreating] = useState(false);
  const [bulkUserCreateMode, setBulkUserCreateMode] = useState(false);
  const loggedInUser = useRecoilValue(myInfoState);
  const isEditingExistingUser = user !== null;
  const isCreatingNewUser = user === null;
  const isEditingSelf =
    isEditingExistingUser && user?.username === loggedInUser?.username;

  const { openAlertSnackbar } = useAlertSnackbar();
  const { getConfirmation } = useConfirmModal();

  const {
    register,
    handleSubmit: handleFormSubmit,
    watch,
    setError,
    clearErrors,
    reset,
    resetField,
    setValue,
    control,
    formState: { errors, isValid, touchedFields, isDirty },
  } = useForm<AccountForm>({
    resolver: zodResolver(finalSchema(isCreatingNewUser, bulkUserCreateMode)),
    mode: 'all',
  });

  const watchPassword = watch('password');
  const watchPasswordConfirm = watch('passwordConfirm');

  useLayoutEffect(() => {
    setValue('isAdmin', !!user?.isAdmin);
    setValue('username', user?.username || '');
    setValue('name', user?.name || '');
    setValue('checkCXR', !!user?.permittedModalities.includes(Modality.CXR));
    setValue('checkMMG', !!user?.permittedModalities.includes(Modality.MMG));
    setValue('checkDBT', !!user?.permittedModalities.includes(Modality.DBT));
  }, [user, setValue, isEditingExistingUser]);

  useEffect(() => {
    if (touchedFields.passwordConfirm && watchPassword !== watchPasswordConfirm)
      setError('passwordConfirm', {
        type: 'validate',
        message: SCHEMA_VALIDATION_ERROR.PASSWORDS_MISMATCH,
      });
    else clearErrors('passwordConfirm');
  }, [
    clearErrors,
    setError,
    touchedFields.passwordConfirm,
    watchPassword,
    watchPasswordConfirm,
  ]);

  const confirmBulkUserCreate = async (data: AccountForm) => {
    const confirmation = await getConfirmation({
      title: 'Bulk User Create Confirmation',
      description: `You are going to create ${data.count} users in this sequence ${data.username}0, ${data.username}1...`,
    });
    return confirmation;
  };

  const getPermittedModalities = (data: AccountForm) => {
    return [
      data.checkCXR ? Modality.CXR : undefined,
      data.checkMMG ? Modality.MMG : undefined,
      data.checkDBT ? Modality.DBT : undefined,
    ].filter((value): value is Modality => value !== undefined);
  };

  const handleSubmit = handleFormSubmit(async data => {
    if (bulkUserCreateMode) {
      const bulkUserConfirmation = await confirmBulkUserCreate(data);
      if (!bulkUserConfirmation) {
        setIsCreating(false);
        onClose?.();
        return;
      }
    }

    setIsCreating(true);

    const permittedModalities = getPermittedModalities(data);
    const [defaultModality] = permittedModalities;

    try {
      if (!defaultModality) {
        throw new Error('Target modality is not set.');
      }

      const modalitiesProps = {
        permittedModalities,
        defaultModality,
      };

      if (isEditingExistingUser) {
        const modifyUserPayload: ModifyUserProps = {
          id: user?.id || '',
          isAdmin: data.isAdmin,
          name: data.name || '',
          ...modalitiesProps,
        };

        await modifyUser(modifyUserPayload);
        openAlertSnackbar({
          severity: 'success',
          description: `User '${data.username}' has been modified.`,
        });
      }

      if (isCreatingNewUser) {
        const passwordProps = {
          password1: data.password || '',
          password2: data.passwordConfirm || '',
        };

        const sharedPayload = { ...passwordProps, ...modalitiesProps };

        const bulkUserPayload: SignUpProps[] = [
          ...Array(data.count).keys(),
        ].map(index => ({
          username: `${data.username}${index}`,
          name: `${data.username}${index}`,
          isAdmin: false,
          ...sharedPayload,
        }));

        const singleUserPayload: SignUpProps[] = [
          {
            username: data.username,
            name: data.name || data.username,
            isAdmin: data.isAdmin,
            ...sharedPayload,
          },
        ];

        await signUp(bulkUserCreateMode ? bulkUserPayload : singleUserPayload);

        if (bulkUserCreateMode) {
          openAlertSnackbar({
            severity: 'success',
            description: `${data.count} of new users have been created with the naming of '${data.username}'.`,
          });
        } else {
          openAlertSnackbar({
            severity: 'success',
            description: `A new user '${data.username}' has been created.`,
          });
        }
      }

      reset();
      setBulkUserCreateMode(false);
      onClose?.();
    } catch (error) {
      openAlertSnackbar({
        severity: 'error',
        description: isEditingExistingUser
          ? `Failed to edit user ${user?.username}: ${error}`
          : bulkUserCreateMode
          ? `Failed to create multiple new users : ${error}`
          : `Failed to create a new user : ${error}`,
      });
    }
    setIsCreating(false);
  });

  const handleUserCreateMode = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setBulkUserCreateMode(checked);
    resetField('count');
  };

  const handleClose = () => {
    setIsCreating(false);
    setBulkUserCreateMode(false);
    reset();
    onClose?.();
  };

  return (
    <CommonDialog
      onClose={handleClose}
      open={open}
      title={
        bulkUserCreateMode
          ? 'Create Multiple Annotators'
          : isCreatingNewUser
          ? 'Create Annotator'
          : 'Edit Annotator'
      }
      maxWidth="xs"
      testId={`dialog-${isCreatingNewUser ? 'create' : 'edit'}-annotator`}
    >
      <form action="#" noValidate>
        <DialogContent style={{ paddingTop: 0 }}>
          {isCreatingNewUser && (
            <Flexbox
              $justify="space-between"
              $align="baseline"
              $direction="column"
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={bulkUserCreateMode}
                    onChange={handleUserCreateMode}
                    data-testid="toggle-bulkUserCreateMode"
                  />
                }
                label="Enable bulk create"
              />
            </Flexbox>
          )}

          {bulkUserCreateMode && (
            <BlockQuote>
              <Typography variant="subtitle1" gutterBottom component="div">
                <strong>You can create multiple users with same name.</strong>
                <br />
                If you input name as <strong>"user"</strong> and count as{' '}
                <strong>"4"</strong>, you will create next four users at once.
              </Typography>
              <Divider />
              <Typography variant="subtitle1" component="div">
                user0, user1, user2, user3
              </Typography>
            </BlockQuote>
          )}

          {bulkUserCreateMode ? (
            <Grid
              item
              container
              xs={12}
              justifyContent="space-between"
              spacing={1}
            >
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  margin="normal"
                  type="text"
                  size="small"
                  variant="outlined"
                  label="Username"
                  {...register('username')}
                  required
                  helperText={errors.username?.message}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  margin="normal"
                  type="number"
                  size="small"
                  variant="outlined"
                  {...register('count', { valueAsNumber: true })}
                  required
                  label="Count"
                  helperText={errors.count?.message}
                  defaultValue={1}
                />
              </Grid>
            </Grid>
          ) : (
            <>
              <Controller
                name="username"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    name="username"
                    fullWidth
                    margin="normal"
                    type="text"
                    size="small"
                    variant="outlined"
                    label="Username"
                    disabled={isEditingExistingUser}
                    required
                    value={value}
                    onChange={onChange}
                    helperText={errors.username?.message}
                  />
                )}
              />
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    name="name"
                    size="small"
                    type="text"
                    variant="outlined"
                    label="Name"
                    required
                    margin="normal"
                    value={value}
                    onChange={onChange}
                    helperText={errors.name?.message}
                  />
                )}
              />
            </>
          )}

          {isCreatingNewUser && (
            <>
              <TextField
                fullWidth
                type="password"
                size="small"
                variant="outlined"
                margin="normal"
                label="Password"
                required={isCreatingNewUser}
                autoComplete="new-password"
                {...register('password')}
                helperText={errors.password?.message}
              />
              <Typography variant="caption">
                Password must be at least 10 characters including at least 1
                uppercase letter, 1 lowercase letter, 1 number and 1 special
                character: #?!@$^%&*-
              </Typography>
              <TextField
                fullWidth
                margin="normal"
                type="password"
                size="small"
                variant="outlined"
                label="Password Confirm"
                required={isCreatingNewUser}
                {...register('passwordConfirm')}
                helperText={errors.passwordConfirm?.message}
              />
            </>
          )}
          {!bulkUserCreateMode && (
            <Controller
              name="isAdmin"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      data-testid="toggle-isAdmin"
                      disabled={isEditingSelf}
                    />
                  }
                  label="Admin"
                />
              )}
            />
          )}

          <Typography marginTop={'2rem'}>Permission</Typography>
          <PermissionChecks>
            <Controller
              name="checkMMG"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value}
                      onChange={onChange}
                      data-testid="toggle-MMG"
                    />
                  }
                  label="MMG"
                />
              )}
            />
            <Controller
              name="checkCXR"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value}
                      onChange={onChange}
                      data-testid="toggle-CXR"
                    />
                  }
                  label="CXR"
                />
              )}
            />
            <Controller
              name="checkDBT"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value}
                      onChange={onChange}
                      data-testid="toggle-DBT"
                    />
                  }
                  label="DBT"
                />
              )}
            />
            <div className="checkbox-description" style={{ fontSize: 11 }}>
              Please check at least one permission
            </div>
          </PermissionChecks>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            disabled={!isValid || !isDirty || isCreating}
          >
            {isCreating ? <CircularProgress size={15} /> : 'Submit'}
          </Button>
        </DialogActions>
      </form>
    </CommonDialog>
  );
}

import { UserSchema } from 'src/types/api/auth';
import { PathNames } from 'src/types/client/url';
import { User } from 'src/types/client/user';
import cookieHelper from 'src/utils/cookieHelper';
import { authApi } from 'src/utils/http';
import { UrlUtil } from 'src/utils/url';
import { getMappedUser } from 'src/utils/user';

type LoginSchema = {
  username: string;
  password: string;
};

type LoginResultSchema = {
  access_token: string;
  token_type?: string;
};

export const login = async ({
  username,
  password,
}: LoginSchema): Promise<void> => {
  const { data } = await authApi.post<LoginResultSchema>(`/login`, {
    username,
    password,
  });

  cookieHelper.setAccessToken(data.access_token);
};

interface UserProps {
  name: string;
  isAdmin?: boolean;
  permittedModalities: string[];
  defaultModality: string;
}
export interface ModifyUserProps extends UserProps {
  id: string;
}

export interface SignUpProps extends UserProps {
  username: string;
  password1: string;
  password2: string;
}

// Create a new user
export const signUp = async (props: SignUpProps[]): Promise<UserSchema> => {
  const payload = props.map(({ isAdmin, ...rest }) => ({
    ...rest,
    is_admin: Boolean(isAdmin),
  }));

  const { data } = await authApi.post<UserSchema>(`/signup`, payload);
  return data;
};

// Modify an existing user
export const modifyUser = async (
  props: ModifyUserProps
): Promise<UserSchema> => {
  const { id, isAdmin, ...payloadProps } = props;
  const payload = { ...payloadProps, is_admin: Boolean(isAdmin) };
  const { data } = await authApi.patch<UserSchema>(`/users/${id}`, payload);
  return data;
};

export const logout = (): void => {
  cookieHelper.removeAccessToken();
  window.location.assign(UrlUtil.getUrl(PathNames.LOGIN));
};

export const getMyInfo = async (): Promise<User> => {
  const { data } = await authApi.get<UserSchema>(`/me`);
  return getMappedUser(data);
};

export const getUsersInfo = async (): Promise<User[]> => {
  const { data } = await authApi.get<UserSchema[]>(`/users`);
  return data.map(getMappedUser);
};

export const getUserInfo = async (id: string): Promise<User> => {
  const { data } = await authApi.get<UserSchema>(`/users/${id}`);
  return getMappedUser(data);
};

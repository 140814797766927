import React from 'react';

import { debounce } from 'lodash';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { ExportAnnotationsPurpose, exportAnnotations } from 'src/api/projects';
import useAlertSnackbar from 'src/hooks/useAlertSnackbar';
import useCheckedParams from 'src/hooks/useCheckedParams';
import { Modality } from 'src/types/api/data/image';

const ExportAnnotationsButton = (): JSX.Element => {
  const { modalityLabel, projectId } = useCheckedParams<{
    modalityLabel: Modality;
    projectId: string;
  }>(['modalityLabel', 'projectId']);

  const { openAlertSnackbar } = useAlertSnackbar();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openExportAnnotationsMenu = Boolean(anchorEl);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const handleOpenExportAnnotationsMenu = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseExportAnnotationsMenu = () => {
    setAnchorEl(null);
  };

  // delay enabling export buttons to prevent multiple API calls
  const enableExportAnnotations = debounce(() => setIsDisabled(false), 300);

  const handleExportAnnotations = async (purpose: ExportAnnotationsPurpose) => {
    setIsDisabled(true);
    openAlertSnackbar({
      description: `Exporting data for ${purpose}. Please wait to download the file.`,
      severity: 'info',
    });

    try {
      await exportAnnotations(projectId, modalityLabel, purpose);
      openAlertSnackbar({
        severity: 'success',
        description: `Successfully exported data for ${purpose}.`,
      });
    } catch {
      openAlertSnackbar({
        severity: 'error',
        description: `Failed to export annotations for ${purpose}.`,
      });
    } finally {
      handleCloseExportAnnotationsMenu();
      enableExportAnnotations();
    }
  };

  const exportPurposes: ExportAnnotationsPurpose[] = ['invoicing', 'training'];

  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        style={{ width: 200 }}
        id="basic-button"
        aria-controls={openExportAnnotationsMenu ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openExportAnnotationsMenu ? 'true' : undefined}
        onClick={handleOpenExportAnnotationsMenu}
        disabled={isDisabled}
      >
        {isDisabled ? 'Loading...' : 'Export Annotations'}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openExportAnnotationsMenu}
        onClose={handleCloseExportAnnotationsMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {exportPurposes.map(purpose => (
          <MenuItem
            key={purpose}
            onClick={() => handleExportAnnotations(purpose)}
            style={{ width: 200 }}
            disabled={isDisabled}
          >
            For {purpose}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ExportAnnotationsButton;

import { useState, useMemo, ChangeEvent, useCallback } from 'react';

import { Row } from 'react-table';
import { useRecoilValueLoadable } from 'recoil';

import Add from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

import { SectionTitle } from 'src/components';
import { Vertical } from 'src/components/Alignments';
import FilterPanel from 'src/components/FilterPanel';
import TextFilterInput from 'src/components/Input/TextFilterInput';
import AnnotatorAccountDialog from 'src/components/Modal/AnnotatorAccountDialog';
import Table from 'src/components/Table';
import { useRefreshUserList, userState } from 'src/states/user';
import { User } from 'src/types/client/user';
import { DEFAULT_PAGE_SIZE } from 'src/utils/constants';

const UserDashboard = (): JSX.Element => {
  const userListLoadable = useRecoilValueLoadable(userState.userList);
  const refreshUserList = useRefreshUserList();
  const [userFilter, setUserFilter] = useState<string>('');
  const [isAnnotatorDialogOpen, setIsAnnotatorDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [page, setPage] = useState<number>(1);

  const userList = useMemo(
    () =>
      userListLoadable.state === 'hasValue' ? userListLoadable.contents : [],
    [userListLoadable.contents, userListLoadable.state]
  );

  const isLoading = userListLoadable.state === 'loading';
  const isError = userListLoadable.state === 'hasError';

  const closeAnnotatorDialog = async () => {
    setIsAnnotatorDialogOpen(false);
    refreshUserList();
    setSelectedUser(null);
  };

  // Table elements
  const usersColumnHeader = useMemo(() => {
    return [
      {
        Header: 'User List',
        columns: [
          {
            Header: 'User Name',
            accessor: 'username',
            style: { width: '30%' },
          },
          {
            Header: 'Name',
            accessor: 'name',
            style: { width: '30%' },
          },
          {
            Header: 'Modality Permissions',
            accessor: 'permittedModalities',
            Cell: function renderPermittedModalitiesList(cell: {
              value: string[];
            }) {
              const modalityList = cell.value.map(modality => modality);
              return modalityList.sort().join(', ');
            },
            style: { width: '20%' },
          },
          {
            Header: 'Admin',
            id: 'isAdmin',
            accessor: ({ isAdmin }: User) => (isAdmin ? 'Yes' : 'No'),
          },
        ],
      },
    ];
  }, []);

  const handleChangePage = (event: ChangeEvent<unknown>, page: number) =>
    setPage(page);

  const filteredUserList = useMemo<User[]>(() => {
    const escapedUserFilter = userFilter.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    const userFilterRegEx = new RegExp(escapedUserFilter, 'i');
    return userList.filter(
      user =>
        userFilterRegEx.test(user.username) || userFilterRegEx.test(user.name)
    );
  }, [userFilter, userList]);

  const totalPages = Math.ceil(filteredUserList.length / DEFAULT_PAGE_SIZE);

  const usersOnCurrentPage = useMemo<User[]>(() => {
    const shouldResetPage = page > totalPages;
    if (shouldResetPage) {
      setPage(1);
    }
    return filteredUserList.slice(
      (page - 1) * DEFAULT_PAGE_SIZE,
      page * DEFAULT_PAGE_SIZE
    );
  }, [filteredUserList, page, totalPages]);

  const handleClickRow = useCallback(
    ({ original: user }: Row<User>): void => {
      setSelectedUser(user);
      setIsAnnotatorDialogOpen(true);
    },
    [setSelectedUser, setIsAnnotatorDialogOpen]
  );

  const handleUserFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUserFilter(event.target.value);
  };

  return (
    <>
      <Vertical>
        <SectionTitle>User List</SectionTitle>
        <FilterPanel>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Add />}
            onClick={() => setIsAnnotatorDialogOpen(true)}
            style={{ minWidth: 255 }}
          >
            Create Annotator Account
          </Button>
          <TextFilterInput
            size="small"
            filterBy={userFilter}
            name="userFilter"
            onChange={handleUserFilterChange}
            error={!isLoading && usersOnCurrentPage.length === 0}
          />
        </FilterPanel>
        <Table<User>
          columns={usersColumnHeader}
          data={usersOnCurrentPage}
          isLoading={isLoading}
          isError={isError}
          onClickRow={handleClickRow}
          paginationProps={{
            page,
            count: totalPages,
            onChange: handleChangePage,
          }}
        />
      </Vertical>

      <AnnotatorAccountDialog
        onClose={closeAnnotatorDialog}
        open={isAnnotatorDialogOpen}
        user={selectedUser}
      />
    </>
  );
};

export default UserDashboard;
